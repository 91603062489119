import {useEffect, useMemo} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';

import {
  AddMessageFunction,
  ALLOWED_FILE_TYPE,
  CareGuide,
  FileUploadLinkResponse,
  Message,
  UpdateMessageFunction,
} from '../../../../../shared/interfaces/aIChat';
import {chatState} from '../../../atoms/chatState';
import {selectedPatientState} from '../../../atoms/selectedPatientState';
import {
  CHAT_PROMPT_RESPONSE,
  CHAT_PROMPTS,
  INVERTED_CHAT_PROMPTS,
} from '../../../constants/careGuidePrompts';
import {useAIChat} from '../../../hooks/useAIChat';

interface UseMessagesReturn {
  chat: CareGuide;
  handleClickNewChat: () => void;
  postMessageisLoading: boolean;
  isLoadingChat: boolean;
  addMessage: AddMessageFunction;
  updateMessage: UpdateMessageFunction;
  setChatFromHistory: (conversationId: string) => Promise<void>;
  onDeleteConversation: (conversationId: string) => Promise<void>;
}

type Response = {message: string; isRetry: boolean};

const useMessages = (): UseMessagesReturn => {
  const selectedPatient = useRecoilValue(selectedPatientState);
  const {
    onPostMessage,
    getConversation,
    isLoadingChat,
    postMessageisLoading,
    onDeleteConversation,
  } = useAIChat();

  const defaultMessage: Message = useMemo(
    () => ({
      isUser: false,
      isFile: false,
      fileType: '',
      fileName: '',
      fileShowInChat: '',
      text: '',
      isAMedicalRecord: false,
    }),
    []
  );

  const defaultInitialMessage: Message = useMemo(
    () => ({
      ...defaultMessage,
      text: `How can I help you with ${selectedPatient?.patientName}?`,
    }),
    [selectedPatient, defaultMessage]
  );
  const formatQuotesInMessage = (text: string): string =>
    // eslint-disable-next-line quotes
    text?.replace(/"/g, "'");
  const createNewMessage = (response: Response): Message => ({
    ...defaultInitialMessage,
    text: response.message,
    isRetry: response.isRetry,
  });

  const [chat, setChat] = useRecoilState(chatState);

  useEffect(() => {
    if (chat.messages.length === 1 && chat.messages[0].text === '') {
      setChat(prevChat => ({
        ...prevChat,
        messages: [defaultInitialMessage],
        chatId: null,
      }));
    }
  }, [selectedPatient, setChat, defaultInitialMessage, chat.messages]);

  const updateMessage: UpdateMessageFunction = async (
    messageText: string,
    fileType: ALLOWED_FILE_TYPE | '',
    fileName: string | null,
    saveFileInLinkResponse: FileUploadLinkResponse,
    isAMedicalRecord: boolean
  ) => {
    const messageTextFormatted = formatQuotesInMessage(messageText);
    setChat(prevChat => ({
      ...prevChat,
      messages: prevChat.messages.slice(0, -1),
    }));
    const response = await onPostMessage(
      messageTextFormatted || fileType,
      chat.chatId,
      fileName || null,
      saveFileInLinkResponse,
      isAMedicalRecord
    );
    setChat(prevState => ({
      ...prevState,
      messages: [...prevState.messages, createNewMessage(response)],
    }));
  };

  const addMessage = async (
    messageText: string,
    isFile: boolean,
    fileType: ALLOWED_FILE_TYPE | '',
    fileName: string,
    fileShowInChat: string,
    saveFileInLinkResponse: FileUploadLinkResponse,
    isAMedicalRecord: boolean
  ) => {
    const messageTextFormatted = formatQuotesInMessage(messageText);

    if (messageText === 'How does Care Guide work') {
      setChat(prevState => ({
        ...prevState,
        messages: [
          ...prevState.messages,
          {
            text: 'How does Care Guide work',
            isUser: true,
            isFile: false,
            fileType: '',
            fileName: '',
            fileShowInChat: '',
            isAMedicalRecord: false,
          },
          {
            text: CHAT_PROMPT_RESPONSE,
            isUser: false,
            isFile: false,
            fileType: '',
            fileName: '',
            fileShowInChat: '',
            isAMedicalRecord: false,
          },
        ],
      }));
      return;
    }
    if (chat.messages[chat.messages.length - 1].isRetry) {
      setChat(prevChat => ({
        ...prevChat,
        messages: prevChat.messages.slice(0, -1),
      }));
    }
    setChat(prevState => ({
      ...prevState,
      messages: [
        ...prevState.messages,
        {
          text: messageText,
          isUser: true,
          isFile,
          fileType,
          fileName,
          fileShowInChat,
          isAMedicalRecord,
        },
      ],
    }));
    const response = await onPostMessage(
      CHAT_PROMPTS.get(messageTextFormatted) ?? messageTextFormatted,
      chat.chatId,
      fileName || null,
      saveFileInLinkResponse,
      isAMedicalRecord
    );
    setChat(prevState => ({
      ...prevState,
      messages: [...prevState.messages, createNewMessage(response)],
      chatId: response?.conversation_id ?? chat.chatId,
    }));
  };

  const setChatFromHistory = async (conversationId: string) => {
    const response = await getConversation(conversationId);
    const messages = response?.messages?.map(message => ({
      ...message,
      text: INVERTED_CHAT_PROMPTS.get(message.text) ?? message.text,
    }));
    if (response !== undefined) {
      setChat({
        ...response,
        messages: [defaultInitialMessage, ...(messages ?? [])],
      });
    }
  };

  const handleClickNewChat = () => {
    setChat(prevState => ({
      ...prevState,
      messages: [defaultInitialMessage],
      chatId: null,
    }));
  };

  return {
    chat,
    handleClickNewChat,
    postMessageisLoading,
    isLoadingChat,
    addMessage,
    updateMessage,
    setChatFromHistory,
    onDeleteConversation,
  };
};

export default useMessages;
