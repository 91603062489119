export enum ENROLLMENT_METHOD_ENUM {
  MOBILE_APP = 'mobile_app',
  ONBOARDING_PORTAL = 'onboarding_portal',
}

export type PatientInvitation = {
  consumer_uuid: string;
  first_name: string;
  last_name: string;
  mobile_phone_number: string;
  email: string | null;
  ehr_id: number | undefined;
  mrn: string;
  status?: PatientInvitationStatus;
  invitation_provider_id: number;
  opt_out?: boolean;
  enrollment_method: ENROLLMENT_METHOD_ENUM;
};

export type PatientInvitationStatus =
  | 'PENDING'
  | 'CONFIRMED'
  | 'EXPIRED'
  | 'MISMATCH'
  | 'OPTED_OUT';

export type PatientInvitationStatusLabelAndColor = {
  [key in PatientInvitationStatus]: {
    label: string;
    statusText: string;
    color: string;
  };
};
