import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {FormikHelpers} from 'formik';
import styled from 'styled-components';

import {envConfig} from '../../../../env';
import {BreadCrumbNavigator} from '../../../shared/components/breadCrumbNavigator';
import {InfoDialog} from '../../../shared/components/infoDialog';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {Crumb} from '../../../shared/interfaces/crumb';
import {PatientInvitation} from '../../../shared/interfaces/patient';
import {fetcher} from '../../../shared/utils/fetcher';
import {urls} from '../../../shared/utils/urls';
import {MainHeader} from '../../layout/components/mainHeader';
import {CheckingInvitationStatus} from '../components/checkingInvitationStatus';
import {PatientForm} from '../components/patientForm';
import {PORTAL_PATIENTS_ROUTES} from '../constants/routes';
import {
  INVITATION_SUCCESS_REQUEST_STATUS,
  INVITATION_SUCCESS_REQUEST_STATUS_MESSAGES,
  InvitationError,
} from '../constants/types';

interface InvitePatientPageProps {
  crumbs: Crumb[];
  title: string;
}

const boldText = <b>first and last name match their driver&apos;s license.</b>;
const DESCRIPTION = (
  <>
    Invite your patient via text message to connect with you. To avoid a patient
    mismatch, please be sure the {boldText} Additionally, check the mobile phone
    number for accuracy.
  </>
);

export const InvitePatientPage: React.FC<InvitePatientPageProps> = ({
  crumbs,
  title,
}) => {
  const isNewInvitationFlowEnabled =
    envConfig.REACT_APP_ONBOARDING_INVITATION_FLOW_ENABLED;
  const history = useHistory();
  const handleError = useErrorHandling();
  const showMessage = useCustomizedSnackbar();
  const [isCheckingInviteStatus, setIsCheckingInviteStatus] =
    useState<boolean>(false);

  const handleNavigate = useCallback(
    (isEnrolled: boolean, values: PatientInvitation) => {
      history.push({
        pathname:
          PORTAL_PATIENTS_ROUTES.CONNECTIONS_INVITE_PATIENT_ENROLLMENT_STATUS,
        state: {isEnrolled, values},
      });
    },
    [history]
  );

  const [isOpenInfoDialog, setIsOpenInfoDialog] = useState<boolean>(false);

  const getSuccessfulMessage = (
    response: INVITATION_SUCCESS_REQUEST_STATUS
  ): string => {
    if (
      response ===
      INVITATION_SUCCESS_REQUEST_STATUS.CONNECTION_REQUEST_WAS_SEND_SUCCESSFULLY
    ) {
      return INVITATION_SUCCESS_REQUEST_STATUS_MESSAGES[
        INVITATION_SUCCESS_REQUEST_STATUS
          .CONNECTION_REQUEST_WAS_SEND_SUCCESSFULLY
      ];
    }
    return INVITATION_SUCCESS_REQUEST_STATUS_MESSAGES[
      INVITATION_SUCCESS_REQUEST_STATUS.INVITATION_WAS_SEND_SUCCESSFULLY
    ];
  };

  const handleCompletePatientInvite = useCallback(
    async (
      values: PatientInvitation,
      actions: FormikHelpers<PatientInvitation>
    ) => {
      try {
        // Pending refactor on fetcher to TS to accept types different from null | undefined of data
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: disable-next-line
        const response = await fetcher(urls.invitePatient, 'POST', values);
        showMessage(getSuccessfulMessage(response), 'success');
        history.push(PORTAL_PATIENTS_ROUTES.INVITATIONS);
      } catch (error) {
        if (error instanceof Error) {
          switch (error?.message) {
            case InvitationError.patientIsAlreadyConnected:
              showMessage(
                'This patient is already connected with you.',
                'error'
              );
              break;
            case InvitationError.patientIsEnrolledWithDifferentName:
              setIsOpenInfoDialog(true);
              break;
            case InvitationError.patientExists:
              showMessage('The patient has already been invited.', 'error');
              break;
            case InvitationError.patientOptedOut:
              showMessage(
                'The patient has opt out for receive messages.',
                'error'
              );
              break;
            default:
              return handleError(error);
          }
        } else {
          return handleError(error);
        }
      }
    },
    [handleError, history, showMessage]
  );
  const handleClose = useCallback(() => setIsOpenInfoDialog(false), []);

  const handleSubmit = useCallback(
    async (values: PatientInvitation) => {
      setIsCheckingInviteStatus(true);
      try {
        const {result} = await fetcher(
          urls.checkPatientEnrolled(values.mobile_phone_number)
        );
        setIsCheckingInviteStatus(false);
        handleNavigate(result, values);
      } catch (e) {
        setIsCheckingInviteStatus(false);
        handleError(e);
      }
    },
    [handleError, handleNavigate]
  );

  if (isCheckingInviteStatus) {
    return <CheckingInvitationStatus />;
  }
  return (
    <>
      <Container>
        <BreadCrumbNavigator crumbs={crumbs} title={title} />
        <Body>
          <MainHeader
            title="Invite Patient to Connect"
            description={DESCRIPTION}
          />
        </Body>
        <PatientForm
          handleSubmit={
            isNewInvitationFlowEnabled
              ? handleSubmit
              : handleCompletePatientInvite
          }
          isSend
          isInsertMode
          isHideEmail={!isNewInvitationFlowEnabled}
          isEmailRequired={isNewInvitationFlowEnabled}
        />
      </Container>
      <InfoDialog
        title="Mobile number is already registered"
        content="The mobile number you entered is already assigned to another patient. Please contact the patient to verify their name and phone number. You will need to resend the patient invitation with the corrected information."
        isOpen={isOpenInfoDialog}
        onClose={handleClose}
        buttonTitle="Close"
      />
    </>
  );
};

const Container = styled.div`
  width: 100%;
  padding-bottom: 100px;
`;

const Body = styled.div`
  margin: 24px 0 0 40px;
`;
