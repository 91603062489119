import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {FormikHelpers} from 'formik';
import {useSetRecoilState} from 'recoil';
import styled from 'styled-components';
import useSWR from 'swr';

import {BreadCrumbNavigator} from '../../../shared/components/breadCrumbNavigator';
import {LoadingBar} from '../../../shared/components/loadingBar';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {Crumb} from '../../../shared/interfaces/crumb';
import {PatientInvitation} from '../../../shared/interfaces/patient';
import {fetcher, HttpMethod} from '../../../shared/utils/fetcher';
import {HttpResponseError} from '../../../shared/utils/httpResponseError';
import {urls} from '../../../shared/utils/urls';
import {formatNames} from '../../portalUsers/utils/formatter';
import {handleResendInvitationModalWindowsState} from '../atoms/handleResendInvitationModalWindowsState';
import {selectedPatientState} from '../atoms/selectedPatientState';
import {PatientForm} from '../components/patientForm';
import {PatientInfoMisMatchModalWindow} from '../components/patientInfoMismatchModalWindow';
import {initialResendInvitationModalWindowsState} from '../constants/initialResendInvitationModalWindowsState';
import {PORTAL_PATIENTS_ROUTES} from '../constants/routes';
import {HANDLE_RESEND_INVITATION_RESPONSE} from '../constants/types';

interface InvitationDetailPageProps {
  crumbs: Crumb[];
  title: string;
}

type InvitationDetailParams = {
  invitationId: string;
};

interface InvitationDetailRouteState {
  startEditing?: boolean;
}

export const InvitationDetailPage: React.FC<InvitationDetailPageProps> = ({
  crumbs,
  title,
}) => {
  const {invitationId} = useParams<InvitationDetailParams>();
  const {state} = useLocation<InvitationDetailRouteState>();
  const setCurrentPatient = useSetRecoilState(selectedPatientState);
  const [editMode, setEditMode] = useState(state?.startEditing ?? false);
  const [isLoadingResendInvitation, setIsLoadingResendInvitation] =
    useState(false);
  const showMessage = useCustomizedSnackbar();
  const handleError = useErrorHandling(
    'Something went wrong. Please try again later.'
  );
  const history = useHistory();
  const {data: invitationDetails, isValidating: isLoadingInvitationDetails} =
    useSWR<PatientInvitation>(urls.patientInvitation(invitationId));

  const setHandleResendInvitationDialogState = useSetRecoilState(
    handleResendInvitationModalWindowsState
  );

  const handleResendInvitationErrorCases = useCallback(
    (error: HttpResponseError) => {
      switch (error.message) {
        case HANDLE_RESEND_INVITATION_RESPONSE.PHONE_NUMBER_ALREADY_REGISTERED:
          setHandleResendInvitationDialogState({
            ...initialResendInvitationModalWindowsState,
            isShowMobilePhoneAlreadyRegisteredModalWindow: true,
          });
          break;
        case HANDLE_RESEND_INVITATION_RESPONSE.PATIENT_ALREADY_CONNECTED:
          setHandleResendInvitationDialogState({
            ...initialResendInvitationModalWindowsState,
            isShowPatientAlreadyConnectedModalWindow: true,
          });
          break;
        case HANDLE_RESEND_INVITATION_RESPONSE.INVITATION_EXISTS:
          showMessage('The patient has already been invited.', 'error');
          break;
        case HANDLE_RESEND_INVITATION_RESPONSE.PATIENT_OPTED_OUT:
          showMessage('The patient has opt out for receive messages.', 'error');
          break;
        default:
          handleError(error);
      }
    },
    [handleError, setHandleResendInvitationDialogState, showMessage]
  );

  const handleResendInvite = useCallback(
    async (values?: PatientInvitation) => {
      setIsLoadingResendInvitation(true);
      try {
        const {resolution} = await fetcher(
          urls.resendInvitePatient(invitationId),
          HttpMethod.PATCH,
          // Pending refactor on fetcher to TS to accept types different from null | undefined of data
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: disable-next-line
          values
        );

        if (resolution === HANDLE_RESEND_INVITATION_RESPONSE.SMS_SENT) {
          showMessage('Your invitation was resent successfully.', 'success');
          history.push(PORTAL_PATIENTS_ROUTES.INVITATIONS);
        } else {
          setHandleResendInvitationDialogState({
            ...initialResendInvitationModalWindowsState,
            isShowPatientAlreadyEnrolledModalWindow: true,
          });
        }
      } catch (error) {
        handleResendInvitationErrorCases(error as HttpResponseError);
      } finally {
        setIsLoadingResendInvitation(false);
      }
    },
    [
      handleResendInvitationErrorCases,
      history,
      invitationId,
      setHandleResendInvitationDialogState,
      showMessage,
    ]
  );

  const handleSubmitFunction = useCallback(
    async (
      values: PatientInvitation,
      actions: FormikHelpers<PatientInvitation>
    ) => {
      const invitation = values;
      delete invitation.status;
      handleResendInvite(invitation);
    },
    [handleResendInvite]
  );

  const setEditModeCallbackTrue = useCallback(() => setEditMode(true), []);
  const setEditModeCallbackFalse = useCallback(() => setEditMode(false), []);

  useEffect(() => {
    if (invitationDetails) {
      setCurrentPatient({
        patientName: formatNames(invitationDetails),
        patientUuid: null,
      });
    }
  }, [invitationDetails, setCurrentPatient]);

  return (
    <Container>
      <BreadCrumbNavigator crumbs={crumbs} title={title} />
      <LoadingBar
        loading={isLoadingInvitationDetails || isLoadingResendInvitation}
      />
      {invitationDetails && (
        <>
          <PatientForm
            handleSubmit={handleSubmitFunction}
            handleResendInvite={handleResendInvite}
            editingFromList={state?.startEditing}
            currentInvite={invitationDetails}
            isInsertMode={editMode}
            startEditing={setEditModeCallbackTrue}
            cancelEdit={setEditModeCallbackFalse}
            isEmailRequired={false}
          />
          {editMode ? (
            <PatientInfoMisMatchModalWindow
              invitationStatus={invitationDetails?.status}
            />
          ) : null}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;
