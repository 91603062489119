export const CHAT_PROMPT_RESPONSE = `Let me provide a brief overview of how Care Guide works. I am able to give you responses to your questions based on the patient’s medical records and my extensive knowledge of medicine.. Here are a few ways you can start a conversation:


1. **Ask a Question:** You can ask any medical question by simply typing your question in the field below and tapping the send button.
2. **Add a File:** You can attach a pdf of a lab report, medical summary or any other document to start a chat. Click the “+” button and select “Add File”. Search for the file on your desktop or in the patient’s medical files. Then ask a question about the file. Finally, tap the send button
3. **Add a Photo:** If you would like to ask me a question about a photo, click the “+” button at the bottom and then select “Add Photo” to search for the image. Select the image and then enter your question. Then just tap the send button.

Please remember Care Guide is intended for informational purposes only and should not be considered a substitute for clinical guidance or protocols provided by licensed medical practitioners. It is designed to support, not replace, the clinical decision-making process.

Let’s get started. What would you like to discuss?`;

export const CHAT_PROMPTS = new Map<string, string>([
  ['How does Care Guide work', ''],
  [
    'Generate patient summary',
    'Create a report for this patient with four sections: Demographics, Conditions, and Active Medications, and Allergies. Print these three section titles in boldface and everything else in normal text. In Conditions, include all conditions grouped by body system and order chronologically by diagnosis date. Print the body system title in italics. Include all data as bulleted lists. If no data are available in the lists, do not print the labels, titles, or separators. For Allergies, create a bullet list with the names of each allergy and include any information related to each allergy in a sub-bullet list. If a status is included for the allergy, include it in the sub-bullet list.',
  ],
  ['Qualify patient for a trial', 'trials_agent'],
  ['Prepare a prescription', 'prep_prescription_agent'],
  ['Justify a prior authorization', 'prior_authorization_agent'],
]);

export const INVERTED_CHAT_PROMPTS = new Map(
  Array.from(CHAT_PROMPTS.entries()).map(([key, value]) => [value, key])
);

export const getChatPromptKeyByStringStartWith = (
  str: string
): string | undefined =>
  Array.from(CHAT_PROMPTS.entries()).find(([key, value]) =>
    value.startsWith(str)
  )?.[0];
