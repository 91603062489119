import React, {
  ChangeEvent,
  InputHTMLAttributes,
  useCallback,
  useMemo,
} from 'react';
import ReactInputMask from 'react-input-mask';
import {useHistory} from 'react-router-dom';
import {FormControl, Tooltip} from '@material-ui/core';
import {Field, Form, Formik, FormikHelpers} from 'formik';
import {useRecoilState, useRecoilValue} from 'recoil';
import styled from 'styled-components';
import * as yup from 'yup';

import PenIcon from '../../../../assets/icons/edit.svg';
import ResendIcon from '../../../../assets/icons/resend.svg';
import {currentUserState} from '../../../shared/atoms/authAtom';
import {Button, CancelButton} from '../../../shared/components/button';
import {CustomizedSelect} from '../../../shared/components/customizedSelect';
import {
  INPUT_WIDTH_TYPES,
  InputField,
  InputFormControlWithFormik,
  InputHelperText,
  InputLabel,
} from '../../../shared/components/form';
import {StatusText} from '../../../shared/components/statusText';
import {Subheader} from '../../../shared/components/subheader';
import {replaceToNumbersPattern} from '../../../shared/constants/patterns';
import {PatientInvitation} from '../../../shared/interfaces/patient';
import {User} from '../../../shared/interfaces/user';
import {colors} from '../../../shared/styles/theme';
import {
  checkAddFormButtonDisability,
  checkEditFormButtonDisability,
} from '../../../shared/utils/formikUtils';
import {getFullName} from '../../../shared/utils/getFullName';
import {formatNames} from '../../portalUsers/utils/formatter';
import {useEhrs} from '../../relyingParties/hooks/useEhrs';
import {handleResendInvitationModalWindowsState} from '../atoms/handleResendInvitationModalWindowsState';
import {defaultPatient} from '../constants/defaultPatient';
import {getCurrentInvitationStatus} from '../utils/getCurrentInvitationStatus';

import {MobilePhoneAlreadyRegisteredModalWindow} from './mobilePhoneAlreadyRegisteredModalWindow';
import {PatientAlreadyConnectedModalWindow} from './patientAlreadyConnectedModalWindow';
import {PatientAlreadyEnrolledModalWindow} from './patientAlreadyEnrolledModalWindow';

const LINK_CONTACT_SUPPORT =
  'https://allclearid.atlassian.net/servicedesk/customer/portals';

const HeaderButtons = ({
  formik,
  onBack,
  handleResendInvite,
  currentInvitation,
  startEditing,
  isOptOut,
  isInsertMode,
}: {
  formik: FormikNeededParams;
  onBack: {
    (formik: FormikNeededParams): void;
    (arg0: FormikNeededParams): any;
  };
  isOptOut: boolean | undefined;
  isInsertMode: boolean;
  handleResendInvite: ((val?: PatientInvitation) => void) | undefined;
  currentInvitation: PatientInvitation;
  startEditing: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  if (isInsertMode) {
    return (
      <EditModeHeaderButtons
        formik={formik}
        onBack={onBack}
        isOptOut={isOptOut}
      />
    );
  }
  return (
    <NonEditModeHeaderButtons
      handleResendInvite={handleResendInvite}
      currentInvitation={currentInvitation}
      startEditing={startEditing}
      isOptOut={isOptOut}
    />
  );
};

const ContactInfo = ({
  formik,
  values,
  isInsertMode,
  handleOnChange,
  handleOnBlur,
  isEmailRequired,
  isHideEmail,
}: {
  formik: FormikNeededParams;
  values: PatientInvitation;
  isInsertMode: boolean;
  handleOnChange: (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string,
    setFieldValueFunc: (
      field: string,
      value: string,
      shouldValidate?: boolean
    ) => void
  ) => void;
  handleOnBlur: (
    fieldName: string,
    setFieldTouchedFunc: (
      field: string,
      isTouched?: boolean,
      shouldValidate?: boolean
    ) => void
  ) => void;
  isEmailRequired: boolean;
  isHideEmail?: boolean;
}) => (
  <ContactInfoContainer>
    <InputFormControlWithFormik
      required={isInsertMode}
      id="firstName"
      name="first_name"
      label="First Name"
      testIdPrefix="patient-first-name"
      width={INPUT_WIDTH_TYPES.SMALL}
      disabled={!isInsertMode}
      error={(formik.touched.first_name && formik.errors.first_name) || ''}
    />
    <InputFormControlWithFormik
      required={isInsertMode}
      id="lastName"
      name="last_name"
      label="Last Name"
      testIdPrefix="patient-last-name"
      width={INPUT_WIDTH_TYPES.SMALL}
      disabled={!isInsertMode}
      error={(formik.touched.last_name && formik.errors.last_name) || ''}
    />
    <FormControl
      required={isInsertMode}
      disabled={!isInsertMode}
      error={Boolean(
        (formik.touched.mobile_phone_number &&
          formik.errors.mobile_phone_number) ||
          ''
      )}
    >
      <InputLabel
        data-testid="patient-mobile-phone-number-label"
        htmlFor="mobile_phone_number"
      >
        Mobile Phone Number
      </InputLabel>
      <ReactInputMask
        mask="(999) 999-9999"
        id="mobile_phone_number"
        value={values.mobile_phone_number}
        onChange={event =>
          handleOnChange(event, 'mobile_phone_number', formik.setFieldValue)
        }
        onBlur={() =>
          handleOnBlur('mobile_phone_number', formik.setFieldTouched)
        }
      >
        {(inputProps: InputHTMLAttributes<HTMLInputElement>) => (
          <InputField
            {...inputProps}
            name="mobile_phone_number"
            placeholder="(XXX) XXX-XXXX"
            id="mobilePhoneNumber"
            inputProps={{
              'data-testid': 'patient-mobile-phone-number-input',
            }}
            width={INPUT_WIDTH_TYPES.SMALL}
          />
        )}
      </ReactInputMask>
      <InputHelperText data-testid="patient-mobile-phone-number-input-helper">
        {formik.touched.mobile_phone_number && formik.errors.mobile_phone_number
          ? formik.errors.mobile_phone_number
          : ''}
      </InputHelperText>
    </FormControl>
    {!isHideEmail && (
      <InputFormControlWithFormik
        required={!isEmailRequired ? false : isInsertMode}
        id="emailAddress"
        name="email"
        placeholder="name@example.com"
        label="Email Address"
        testIdPrefix="patient-email-address"
        width={INPUT_WIDTH_TYPES.SMALL}
        disabled={!isInsertMode}
        error={(formik.touched.email && formik.errors.email) || ''}
      />
    )}
  </ContactInfoContainer>
);
const EhrMrnInfo = ({
  isInsertMode,
  ehrs,
  initialEhrValueSelect,
  formik,
}: {
  isInsertMode: boolean;
  ehrs: {id: number; name: string}[];
  initialEhrValueSelect: {id: number; name: string};
  formik: FormikNeededParams;
}) => (
  <EhrMrnInfoContainer>
    <HalfColumnContainer>
      <Title>Your System</Title>
      <Field
        required={isInsertMode}
        as={CustomizedSelect}
        id="ehrId"
        name="ehr_id"
        label="Select System"
        testID="select-ehr-input"
        disabled={!isInsertMode}
        options={ehrs.map(({name}) => name)}
        values={ehrs.map(({id}) => id)}
        hiddenValue={initialEhrValueSelect.id}
        error={!!(formik.touched.ehr_id && formik.errors.ehr_id)}
        errorDescription="System is required"
      />{' '}
    </HalfColumnContainer>
    <HalfColumnContainer>
      <Title>Patient Identifier</Title>
      <InputFormControlWithFormik
        required={isInsertMode}
        id="mrn"
        name="mrn"
        label="Enter Identifier"
        testIdPrefix="patient-mrn"
        width={INPUT_WIDTH_TYPES.SMALL}
        disabled={!isInsertMode}
        error={(formik.touched.mrn && formik.errors.mrn) || ''}
      />{' '}
    </HalfColumnContainer>
  </EhrMrnInfoContainer>
);

const WrapperComponent = ({
  values,
  isInsertMode,
  handleResendInvite,
  currentInvitation,
  startEditing,
  currentInvitationStatus,
  isSend,
  handleOnChange,
  handleOnBlur,
  onBack,
  ehrs,
  isEmailRequired,
  isHideEmail,
  ...formik
}: {
  values: PatientInvitation;
  formik: FormikNeededParams;
  isInsertMode: boolean;
  handleResendInvite: ((val?: PatientInvitation) => void) | undefined;
  currentInvitation: PatientInvitation;
  startEditing: React.MouseEventHandler<HTMLButtonElement> | undefined;
  currentInvitationStatus: {
    label: string;
    statusText: string;
    color: string;
  } | null;
  isSend: boolean | undefined;
  handleOnChange: (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string,
    setFieldValueFunc: (
      field: string,
      value: string,
      shouldValidate?: boolean
    ) => void
  ) => void;
  handleOnBlur: (
    fieldName: string,
    setFieldTouchedFunc: (
      field: string,
      isTouched?: boolean,
      shouldValidate?: boolean
    ) => void
  ) => void;
  onBack: {(formik: FormikNeededParams): void; (arg0: FormikNeededParams): any};
  ehrs: {id: number; name: string}[];
  isEmailRequired: boolean;
  isHideEmail?: boolean;
}) => (
  <Form autoComplete="off">
    {!isSend && (
      <Subheader title={formatNames(currentInvitation)}>
        <HeaderButtons
          formik={formik.formik}
          onBack={onBack}
          isOptOut={currentInvitation.opt_out}
          isInsertMode={isInsertMode}
          handleResendInvite={handleResendInvite}
          currentInvitation={currentInvitation}
          startEditing={startEditing}
        />
      </Subheader>
    )}
    <Container>
      <div>
        <StatusContainer>
          <Title>Contact Information</Title>
          {currentInvitationStatus ? (
            <StatusText color={currentInvitationStatus.color}>
              {currentInvitationStatus.statusText}
            </StatusText>
          ) : null}
        </StatusContainer>
        <ContactInfo
          formik={formik.formik}
          values={values}
          isInsertMode={isInsertMode}
          handleOnChange={handleOnChange}
          handleOnBlur={handleOnBlur}
          isEmailRequired={isEmailRequired}
          isHideEmail={isHideEmail}
        />
        <EhrMrnInfo
          isInsertMode={isInsertMode}
          ehrs={ehrs}
          initialEhrValueSelect={initialEhrValueSelect}
          formik={formik.formik}
        />
        {isSend && (
          <CancelAndInviteButton formik={formik.formik} onBack={onBack} />
        )}
      </div>
      <div>
        {currentInvitation.opt_out && (
          <StaticAlert>
            <StaticAlertTitle>Patient has opted out</StaticAlertTitle>
            <StaticAlertBody>
              <p>
                This patient has opted out of receiving invitations from your
                healthcare organization.
              </p>
              <p>Please contact support for help with this invitation.</p>
            </StaticAlertBody>
            <Button href={LINK_CONTACT_SUPPORT} target="_blank">
              Contact Support
            </Button>{' '}
          </StaticAlert>
        )}
      </div>
    </Container>
  </Form>
);

function EditModeHeaderButtons({
  formik,
  onBack,
  isOptOut,
}: {
  isOptOut?: boolean;
  formik: FormikNeededParams;
  onBack: {(formik: FormikNeededParams): void; (arg0: FormikNeededParams): any};
}): JSX.Element | null {
  const onClick = useCallback(() => onBack(formik), [formik, onBack]);

  return !isOptOut ? (
    <>
      <CancelButton position="section" onClick={onClick} />
      <Button
        testID="saveAndResendButton"
        type="submit"
        position="section"
        disabled={checkEditFormButtonDisability(formik)}
      >
        Save & Resend Invitation
      </Button>
    </>
  ) : null;
}

function NonEditModeHeaderButtons({
  handleResendInvite,
  currentInvitation,
  startEditing,
  isOptOut,
}: {
  handleResendInvite: ((val?: PatientInvitation) => void) | undefined;
  currentInvitation: PatientInvitation;
  startEditing: React.MouseEventHandler<HTMLButtonElement> | undefined;
  isOptOut?: boolean;
}): JSX.Element | null {
  const onClick = useCallback(
    () => handleResendInvite?.(currentInvitation),
    [currentInvitation, handleResendInvite]
  );

  return !isOptOut ? (
    <>
      {currentInvitation.status !== 'MISMATCH' ? (
        <OptOutTooltip isOptOut={currentInvitation.opt_out}>
          <Button
            position="section"
            testID="resendButton"
            startIcon={<img src={ResendIcon} alt="editIcon" />}
            onClick={onClick}
            disabled={currentInvitation.opt_out}
          >
            Resend invitation
          </Button>
        </OptOutTooltip>
      ) : null}
      <Button
        position="section"
        testID="editButton"
        startIcon={<img src={PenIcon} alt="editIcon" />}
        onClick={startEditing}
      >
        Edit
      </Button>
    </>
  ) : null;
}

function CancelAndInviteButton({
  formik,
  onBack,
}: Readonly<{
  formik: FormikNeededParams;
  onBack: {(formik: FormikNeededParams): void; (arg0: FormikNeededParams): any};
}>): JSX.Element {
  const onClick = useCallback(() => onBack(formik), [formik, onBack]);
  return (
    <ButtonContainer>
      <CancelButton onClick={onClick}>Cancel</CancelButton>
      <Button disabled={checkAddFormButtonDisability(formik)} type="submit">
        Invite
      </Button>
    </ButtonContainer>
  );
}

const initialEhrValueSelect = {
  id: 0,
  name: 'Select your system',
};

const patientValidationSchema = (isEmailRequired: boolean) =>
  yup.object({
    first_name: yup
      .string()
      .trim()
      .max(50, 'Must be 50 characters or less')
      .required('First Name is required'),
    last_name: yup
      .string()
      .trim()
      .max(50, 'Must be 50 characters or less')
      .required('Last Name is required'),
    mobile_phone_number: yup
      .string()
      .trim()
      .matches(
        /^[1-9]\d{9}$/,
        'Phone number must be 10 digits and cannot start with 0'
      )
      .required('Mobile Phone Number is required'),
    email: yup
      .string()
      .trim()
      .max(200, 'Must be 200 characters or less')
      .email('Please enter a valid email address')
      .nullable()
      .when([], {
        is: () => isEmailRequired,
        then: yup.string().required('Email Address is required'),
      }),
    ehr_id: yup.number().notOneOf([initialEhrValueSelect.id]),
    mrn: yup.string().trim().required('Patient Identifier is required'),
  });

interface PatientFormProps {
  currentInvite?: PatientInvitation;
  handleSubmit: (
    values: PatientInvitation,
    actions: FormikHelpers<PatientInvitation>
  ) => void;
  isSend?: boolean;
  isInsertMode: boolean;
  handleResendInvite?: () => void;
  editingFromList?: boolean;
  startEditing?: () => void;
  cancelEdit?: () => void;
  isEmailRequired?: boolean;
  isHideEmail?: boolean;
}

type FormikNeededParams = {
  resetForm: () => void;
  isValidating: boolean;
  isValid: boolean;
  isSubmitting: boolean;
  dirty: boolean;
  errors: {
    first_name?: string;
    last_name?: string;
    mobile_phone_number?: string;
    email?: string;
    ehr_id?: string;
    mrn?: string;
  };
  touched: {
    first_name?: boolean;
    last_name?: boolean;
    mobile_phone_number?: boolean;
    email?: boolean;
    ehr_id?: boolean;
    mrn?: boolean;
  };
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

interface OptOutTooltipProps {
  isOptOut?: boolean;
  children: React.ReactNode;
}

const OptOutTooltip: React.FC<OptOutTooltipProps> = ({isOptOut, children}) => (
  <Tooltip
    data-testid="optOutTooltip"
    style={{marginRight: 10}}
    title={isOptOut ? 'Patient has Opted-Out' : ''}
  >
    <div>{children}</div>
  </Tooltip>
);

export const PatientForm: React.FC<PatientFormProps> = ({
  currentInvite = defaultPatient,
  handleSubmit,
  isSend,
  isInsertMode,
  handleResendInvite,
  editingFromList,
  startEditing,
  cancelEdit,
  isHideEmail,
  isEmailRequired = true,
}) => {
  const history = useHistory();
  const [
    {
      isShowMobilePhoneAlreadyRegisteredModalWindow,
      isShowPatientAlreadyConnectedModalWindow,
      isShowPatientAlreadyEnrolledModalWindow,
    },
    setHandleResendInvitationDialogState,
  ] = useRecoilState(handleResendInvitationModalWindowsState);

  const onBack = (formik: FormikNeededParams) => {
    if (isSend || editingFromList) {
      history.goBack();
    } else {
      formik.resetForm();
      if (cancelEdit) cancelEdit();
    }
  };

  const {id, relyingParty} =
    useRecoilValue<User | null>(currentUserState) ??
    (() => {
      throw new Error('Not expected error, please reload the page');
    })();

  const ehrs = [initialEhrValueSelect, ...useEhrs(relyingParty.id)];

  const currentInvitation = useMemo(
    () => ({...currentInvite, invitation_provider_id: id}),
    [currentInvite, id]
  );

  const handleOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string,
    setFieldValueFunc: (
      field: string,
      value: string,
      shouldValidate?: boolean
    ) => void
  ) => {
    const unMaskPhoneNumber = event.target.value.replace(
      replaceToNumbersPattern,
      ''
    );
    setFieldValueFunc(fieldName, unMaskPhoneNumber);
  };

  const handleOnBlur = (
    fieldName: string,
    setFieldTouchedFunc: (
      field: string,
      isTouched?: boolean,
      shouldValidate?: boolean
    ) => void
  ) => setFieldTouchedFunc(fieldName, true);

  const currentInvitationStatus = useMemo(
    () =>
      getCurrentInvitationStatus({
        opt_out: currentInvitation.opt_out,
        status: currentInvitation.status,
      }),
    [currentInvitation]
  );

  return (
    <>
      <Formik
        validateOnMount
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={currentInvitation}
        validationSchema={patientValidationSchema(isEmailRequired)}
      >
        {({values, ...formik}) => (
          <>
            <WrapperComponent
              values={values}
              formik={formik}
              isInsertMode={isInsertMode}
              handleResendInvite={handleResendInvite}
              currentInvitation={currentInvitation}
              startEditing={startEditing}
              currentInvitationStatus={currentInvitationStatus}
              isSend={isSend}
              handleOnChange={handleOnChange}
              handleOnBlur={handleOnBlur}
              onBack={onBack}
              ehrs={ehrs}
              isEmailRequired={isEmailRequired}
              isHideEmail={isHideEmail}
            />

            <PatientAlreadyConnectedModalWindow
              patientName={getFullName(values.first_name, values.last_name)}
              isShowModalWindow={isShowPatientAlreadyConnectedModalWindow}
              setIsShowModalWindow={setHandleResendInvitationDialogState}
            />
          </>
        )}
      </Formik>

      <PatientAlreadyEnrolledModalWindow
        isShowModalWindow={isShowPatientAlreadyEnrolledModalWindow}
        setIsShowModalWindow={setHandleResendInvitationDialogState}
      />
      <MobilePhoneAlreadyRegisteredModalWindow
        isShowModalWindow={isShowMobilePhoneAlreadyRegisteredModalWindow}
        setIsShowModalWindow={setHandleResendInvitationDialogState}
      />
    </>
  );
};

const Title = styled.p`
  font-weight: 700;
  line-height: 16px;
  font-size: 14px;
`;

const StatusContainer = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 8px;
  align-items: center;
`;

const Container = styled.div`
  margin-left: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
`;

const StaticAlert = styled.div`
  margin-right: 60px;
  margin-top: 24px;
  max-width: 464px;
  border-radius: 8px;
  background-color: ${colors.grey40};
  display: block;
  padding: 24px;
  box-sizing: border-box;
`;

const StaticAlertTitle = styled.h5`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 16px 0;
`;

const StaticAlertBody = styled.div`
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
  margin: 0 0 24px 0;
`;

const ContactInfoContainer = styled.div`
  width: 520px;
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 20px;
  grid-row-gap: 2px;
  margin-bottom: 36px;
`;

const EhrMrnInfoContainer = styled.div`
  width: 520px;
  display: flex;
`;

const HalfColumnContainer = styled.div``;

const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
`;
