import React from 'react';
import {CircularProgress} from '@material-ui/core';
import styled from 'styled-components';

import {colors} from '../../../shared/styles/theme';

export const CheckingInvitationStatus: React.FC = () => (
  <Container>
    <CircularProgress size={124} thickness={1.25} />
    <Title>Checking Enrollment Status</Title>
    <Description>
      One moment while we check to see if this
      <br />
      patient is enrolled in Health Bank One.
    </Description>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0px;
  color: ${colors.primary};
`;
const Description = styled.p`
  font-size: 18px;
  line-height: 26px;
  text-align: center;
`;
