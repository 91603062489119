import React, {FC, useCallback} from 'react';
import {Route, Switch} from 'react-router-dom';
import {useRecoilState, useRecoilValue} from 'recoil';

import {InfoDialog} from '../../shared/components/infoDialog';
import {PatientNameClickModalWindowsProvider} from '../../shared/providers/patientNameClickModalWindowsProvider';
import {getCrumbs} from '../../shared/utils/getCrumbs';
import {NotFoundPage} from '../notFound';

import {isViewingRecordNotAvailableState} from './atoms/isViewingRecordNotAvailableState';
import {selectedPatientState} from './atoms/selectedPatientState';
import {PatientMedicalRecordRequest} from './components/patientMedicalRecordRequest';
import {SendRequestModalWindow} from './components/sendReqeustModalWindow';
import {UploadMedicalRecordsData} from './components/uploadMedicalRecordsData';
import {HIDE_MY_CONNECTIONS} from './constants/flags';
import {PORTAL_PATIENTS_ROUTES} from './constants/routes';
import {TAB_INDEX} from './constants/tabIndex';
import {AddProviderPage} from './pages/addProviderPage';
import {EnrolledPatientsPage} from './pages/enrolledPatientsPage';
import {InvitationDetailPage} from './pages/invitationDetailPage';
import {InvitedPatientsPage} from './pages/invitedPatientsPage';
import {InvitePatientPage} from './pages/invitePatientPage';
import {MyPatientsPage} from './pages/myPatientsPage';
import {OrderRecordsPage} from './pages/orderRecordsPage';
import {PatientEnrollmentStatusPage} from './pages/patientEnrollmentStatusPage';
import {PatientProfilePage} from './pages/patientProfilePage';

const routes = [
  {
    path: PORTAL_PATIENTS_ROUTES.CONNECTIONS,
    exact: true,
    component: EnrolledPatientsPage,
    title: 'Patients',
    activeTabIndex: TAB_INDEX.FIRST,
  },
  {
    path: PORTAL_PATIENTS_ROUTES.INVITATIONS,
    exact: true,
    component: InvitedPatientsPage,
    title: 'Patients',
    activeTabIndex: TAB_INDEX.SECOND,
  },
  {
    path: PORTAL_PATIENTS_ROUTES.ORDER_RECORDS,
    exact: true,
    component: OrderRecordsPage,
    title: 'Order Records',
  },
  {
    path: PORTAL_PATIENTS_ROUTES.MY_CONNECTIONS,
    exact: true,
    component: MyPatientsPage,
    title: 'Patients',
    activeTabIndex: TAB_INDEX.THIRD,
    hide: HIDE_MY_CONNECTIONS,
  },
  {
    path: PORTAL_PATIENTS_ROUTES.UPLOAD_MEDICAL_RECORDS_DATA,
    exact: true,
    component: UploadMedicalRecordsData,
  },
  {
    path: PORTAL_PATIENTS_ROUTES.MEDICAL_RECORDS_REQUEST,
    exact: true,
    component: PatientMedicalRecordRequest,
  },
  {
    path: PORTAL_PATIENTS_ROUTES.CONNECTIONS_PATIENT_PROFILE,
    component: PatientProfilePage,
    location: 'connections',
  },
  {
    path: PORTAL_PATIENTS_ROUTES.MY_CONNECTIONS_PATIENT_PROFILE,
    exact: true,
    component: PatientProfilePage,
    location: 'my-connections',
  },
  {
    path: PORTAL_PATIENTS_ROUTES.CONNECTIONS_REQUEST_INVITE_PATIENT,
    exact: true,
    component: InvitePatientPage,
    title: 'Invite Patient',
  },
  {
    path: PORTAL_PATIENTS_ROUTES.CONNECTIONS_INVITE_PATIENT,
    exact: true,
    component: InvitePatientPage,
    title: 'Invite Patient',
  },
  {
    path: PORTAL_PATIENTS_ROUTES.CONNECTIONS_INVITE_PATIENT_ENROLLMENT_STATUS,
    exact: true,
    component: PatientEnrollmentStatusPage,
    title: 'Invite Patient',
  },
  {
    path: PORTAL_PATIENTS_ROUTES.MY_CONNECTIONS_INVITE_PATIENT,
    exact: true,
    component: InvitePatientPage,
    title: 'Invite Patient',
  },
  {
    path: PORTAL_PATIENTS_ROUTES.INVITATION_DETAILS,
    exact: true,
    component: InvitationDetailPage,
  },
  {
    path: PORTAL_PATIENTS_ROUTES.CONNECTIONS_ADD_PROVIDER,
    exact: true,
    component: AddProviderPage,
  },
  {
    path: '*',
    component: NotFoundPage,
  },
];

export const PortalPatientsRoutes: FC = () => {
  const selectedPatient = useRecoilValue(selectedPatientState);

  const [isOpenViewingRecordNotAvailable, setIsOpenViewingRecordNotAvailable] =
    useRecoilState(isViewingRecordNotAvailableState);

  const handleCloseInfoDialog = useCallback(
    () => setIsOpenViewingRecordNotAvailable(false),
    [setIsOpenViewingRecordNotAvailable]
  );

  return (
    <PatientNameClickModalWindowsProvider>
      <Switch>
        {routes.map(route => {
          const ContentComponent = route.component;
          return !route.hide ? (
            <Route
              path={route.path}
              exact={route.exact}
              key={route.path}
              render={props => (
                <ContentComponent
                  {...getCrumbs(
                    props,
                    routes,
                    selectedPatient.patientName ?? ''
                  )}
                  title="Patient management"
                  activeTabIndex={route.activeTabIndex}
                  location={route.location}
                />
              )}
            />
          ) : null;
        })}
      </Switch>
      <SendRequestModalWindow />
      <InfoDialog
        title="Viewing patient record is not available"
        content="The request has already been sent to the patient."
        isOpen={isOpenViewingRecordNotAvailable}
        onClose={handleCloseInfoDialog}
        buttonTitle="Ok"
      />
    </PatientNameClickModalWindowsProvider>
  );
};
