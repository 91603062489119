import React from 'react';
import styled from 'styled-components';

import AuthorizeIcon from '../../../../../assets/icons/aiCareGuideAuthorize.svg';
import InfoIcon from '../../../../../assets/icons/aiCareGuideInfo.svg';
import PrescriptionIcon from '../../../../../assets/icons/aiCareGuidePrescription.svg';
import SummaryIcon from '../../../../../assets/icons/aiCareGuideSummary.svg';
import TrialIcon from '../../../../../assets/icons/aiCareGuideTrial.svg';
import {ArrowIcon} from '../../../../shared/icons/arrowIcon';
import {colors} from '../../../../shared/styles/theme';
import {CHAT_PROMPTS} from '../../constants/careGuidePrompts';

interface Props {
  onClick: (prompt: string) => void;
}

const iconMapper = (prompt: string) => {
  const keysArray = Array.from(CHAT_PROMPTS.keys());

  switch (prompt) {
    case keysArray[0]:
      return <PromptIcon src={InfoIcon} />;
    case keysArray[1]:
      return <PromptIcon src={SummaryIcon} />;
    case keysArray[2]:
      return <PromptIcon src={TrialIcon} />;
    case keysArray[3]:
      return <PromptIcon src={PrescriptionIcon} />;
    case keysArray[4]:
      return <PromptIcon src={AuthorizeIcon} />;
    default:
  }
};

export const SuggestedPrompts: React.FC<Props> = ({onClick}) => (
  <Wrapper>
    {Array.from(CHAT_PROMPTS.keys()).map((prompt, index) => (
      <Container key={index}>
        {iconMapper(prompt)}
        <Text onClick={() => onClick(prompt)} style={{cursor: 'pointer'}}>
          {prompt}{' '}
        </Text>
        <div style={{flexGrow: 1}} />
        <ArrowIcon rotation={-90} />
      </Container>
    ))}
  </Wrapper>
);

const Wrapper = styled.div`
  gap: 16px;
  display: flex;
  padding: 30px 40px;
  flex-direction: column;
  align-items: flex-start;
  width: 85%;
`;

const Container = styled.div`
  display: flex;
  height: 42px;
  align-items: center;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid ${colors.lightPrimary};
  padding-right: 20px;
`;

const Text = styled.button`
  background: none;
  display: flex;
  margin-left: 14px;
  text-align: left;
  gap: 14px;
  border: none;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const PromptIcon = styled.img`
  margin-left: 20px;
`;
