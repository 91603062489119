import React, {FC} from 'react';
import {SvgIcon} from '@material-ui/core';

interface ArrowIconProps {
  rotation?: number;
}

export const ArrowIcon: FC<ArrowIconProps> = ({rotation = 0, ...props}) => (
  <SvgIcon
    {...props}
    fill="none"
    viewBox="0 0 24 24"
    style={{transform: `rotate(${rotation}deg)`}}
  >
    <path
      fill="currentColor"
      d="m4.4 12 7.6 7.6 7.6-7.6-1-1-5.9 5.8V4.3h-1.4v12.3l-6-5.6-1 1.1Z"
    />
  </SvgIcon>
);
