import React, {FC, useMemo, useState} from 'react';
import {
  IconButton,
  InputAdornment,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import ChatFileIcon from '../../../../../assets/icons/chatFileIcon.svg';
import ChatImageIcon from '../../../../../assets/icons/chatImageIcon.svg';
import HeadphonesIconAIChat from '../../../../../assets/icons/headphonesIconAIChat.svg';
import MicrophoneIconAIChat from '../../../../../assets/icons/microphoneIconAIChat.svg';
import PlusIconAIChat from '../../../../../assets/icons/plusIconAIChat.svg';
import SendChatButton from '../../../../../assets/icons/sendChatButton.svg';
import SendChatButtonDisabled from '../../../../../assets/icons/sendChatButtonDisabled.svg';
import {
  MedicalFileObject,
  selectedMedicalFileAtom,
} from '../../../../shared/atoms/medicalFileInAiChat';
import {InputField} from '../../../../shared/components/form';
import {
  ALLOWED_FILE_TYPE,
  ALLOWED_FILE_TYPES_LIST,
  FileUploadLinkResponse,
} from '../../../../shared/interfaces/aIChat';
import {ANCHOR, colors} from '../../../../shared/styles/theme';
import {ChatFooterPreviewFiles} from '../ChatFooterPreviewFiles';

import useMessages from './hooks/useMessages';
import {SuggestedPrompts} from './suggestedPrompts';

export type SelectedFileMedicalRecords = {
  type: string;
  name: string;
};

const useStyles = makeStyles(theme => ({
  removeInputFocus: {
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.backgroundPrimary,
        borderWidth: '1px',
      },
      outlineColor: colors.white,
    },

    '&.MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.backgroundPrimary,
        borderWidth: '1px',
      },
      outlineColor: colors.white,
    },
  },
}));

const ChatFooter: FC<{
  saveFileInLinkResponse: FileUploadLinkResponse;
  selectedFile: File | SelectedFileMedicalRecords | null;
  fileLoading: boolean;
  fileContent: string | ArrayBuffer | null;
  isMedicalFiles: boolean;
  errorInFile: string | null;
  handleRemoveFile: () => void;
  handleOpenModal: () => void;
  handleGetFile: () => void;
}> = ({
  saveFileInLinkResponse,
  selectedFile,
  fileLoading,
  fileContent,
  isMedicalFiles,
  errorInFile,
  handleRemoveFile,
  handleOpenModal,
  handleGetFile,
}) => {
  const selectFileFromMedicalRecord = useRecoilValue<MedicalFileObject>(
    selectedMedicalFileAtom
  );
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setInputValue(event.target.value);
  const {addMessage, chat} = useMessages();
  const shouldShowSuggestions = useMemo(
    () =>
      inputValue.length === 0 && chat?.messages?.length === 1 && !selectedFile,
    [chat?.messages?.length, inputValue.length, selectedFile]
  );

  const isHeadphoneEnabled = false;
  const sendMessage = async (): Promise<void> => {
    const isFile = !!selectedFile;
    const fileType: ALLOWED_FILE_TYPE | '' =
      (selectedFile?.type as ALLOWED_FILE_TYPE) ?? '';
    let fileShowInChat = '';
    if (selectedFile?.type.startsWith('image/')) {
      fileShowInChat = `${fileContent}`;
    } else {
      fileShowInChat = `${selectedFile?.name}`;
    }
    setInputValue('');
    handleRemoveFile();
    if (
      selectFileFromMedicalRecord.file_path ||
      (ALLOWED_FILE_TYPES_LIST.includes(fileType) && __filename !== '')
    ) {
      const fileName = selectedFile?.name ?? '';
      const isAMedicalRecord = isMedicalFiles;
      await addMessage(
        inputValue,
        isFile,
        fileType,
        fileName,
        fileShowInChat,
        saveFileInLinkResponse,
        isAMedicalRecord
      );
    }
  };

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSimulateEnter = () => {
    const enterEvent = new KeyboardEvent('keydown', {
      key: 'Enter',
      keyCode: 13,
      which: 13,
    });
    document.getElementById('input')?.dispatchEvent(enterEvent);
    sendMessage();
  };

  const handleKeyPress = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenFile = () => {
    setAnchorEl(null);
    handleOpenModal();
  };

  const handleOpenPhoto = () => {
    setAnchorEl(null);
    handleGetFile();
  };
  const setFileStyle = (selectedFile: File | SelectedFileMedicalRecords) =>
    selectedFile.type.startsWith('image/') ? '10px' : '8px';

  const getPaddingTop = (
    selectedFile: File | SelectedFileMedicalRecords | null
  ) => {
    if (!selectedFile) return '0px';
    return setFileStyle(selectedFile);
  };

  const getPaddingRight = () => {
    if (!errorInFile) return '0px';
    return '10px';
  };

  const getPaddingLeft = () => {
    if (!errorInFile) return '16px';
    return '10px';
  };

  const microphoneAIFeature = inputValue?.length === 0 && (
    <InputAdornment position="end">
      <IconButton aria-label="toggle password visibility" edge="end">
        <ChatMessageIcon
          data-testid="ai-microphone-button"
          width={24}
          height={24}
          marginRight={8}
          src={MicrophoneIconAIChat}
        />
      </IconButton>
    </InputAdornment>
  );

  const headphoneAIFeature = (
    <ChatMessageIcon
      data-testid="headphone-button"
      width={24}
      height={24}
      src={HeadphonesIconAIChat}
      marginRight={0}
    />
  );

  const disabledSendButtonAIFeature = (
    <ChatMessageIcon
      data-testid="send-chat-button-disabled"
      width={24}
      height={24}
      src={SendChatButtonDisabled}
      marginRight={0}
    />
  );

  const renderSendButton = () =>
    isHeadphoneEnabled ? headphoneAIFeature : disabledSendButtonAIFeature;

  return (
    <BottomContainerWrapper>
      {shouldShowSuggestions && (
        <SuggestedPrompts
          onClick={prompt => {
            addMessage(prompt);
          }}
        />
      )}
      <ChatField>
        <ChatIconButton
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <ChatMessageIcon
            data-testid="add-plus-button"
            marginRight={1}
            src={PlusIconAIChat}
          />
        </ChatIconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          style={ANCHOR.styleChatMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={handleOpenFile} style={ANCHOR.styleChatMenuItem}>
            <ChatIconButton
              role={undefined}
              tabIndex={-1}
              data-testid="add-file-button"
            >
              <ChatMessageIcon width={20} height={20} src={ChatFileIcon} />
              Add File
            </ChatIconButton>
          </MenuItem>
          <MenuItem onClick={handleOpenPhoto} style={ANCHOR.styleChatMenuItem}>
            <ChatIconButton role={undefined} tabIndex={-1}>
              <ChatMessageIcon
                data-testid="add-photo-button"
                width={20}
                height={20}
                src={ChatImageIcon}
              />
              Add Photo
            </ChatIconButton>
          </MenuItem>
        </Menu>
        <InputField
          id="input"
          inputProps={{
            'aria-label': 'input',
            min: 0,
            style: {
              textAlign: 'left',
              flexGrow: 1,
              paddingTop: '10px',
              lineHeight: '24px',
              paddingRight: '5px',
              width: '70%',
              paddingBottom: inputValue.length !== 0 && '5px',
            },
          }}
          width="auto"
          multiline
          maxRows={6}
          value={inputValue === '\n' ? '' : inputValue}
          data-testid="input-field"
          onChange={handleInputChange}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            gap: '10px',
            paddingTop: getPaddingTop(selectedFile),
            paddingBottom: '0px',
            paddingRight: getPaddingRight(),
            marginBottom: '25px',
            backgroundClip: 'padding-box',
            backgroundColor: colors.white,
            boxShadow: fileLoading
              ? `${colors.grey800} 0px 48px 0px 0px inset`
              : 'none',
            alignItems: 'center',
            alignSelf: 'flex-end',
            paddingLeft: getPaddingLeft(),
            minHeight: '44px',
            maxWidth: !isHeadphoneEnabled && '300px',
          }}
          placeholder="Ask any health question"
          onKeyPress={handleKeyPress}
          className={`chat-border ${classes.removeInputFocus}`}
          startAdornment={
            selectedFile ? (
              <ChatFooterPreviewFiles
                errorInFile={errorInFile}
                selectedFile={selectedFile}
                fileLoading={fileLoading}
                handleRemoveFile={handleRemoveFile}
                fileContent={fileContent}
              />
            ) : (
              selectFileFromMedicalRecord.file_path &&
              selectedFile && (
                <ChatFooterPreviewFiles
                  selectedFile={selectedFile}
                  fileLoading={false}
                  handleRemoveFile={handleRemoveFile}
                  medicalFileSelected
                />
              )
            )
          }
          endAdornment={isHeadphoneEnabled && microphoneAIFeature}
        />
        {inputValue || (selectedFile && !fileLoading && !!fileContent) ? (
          <ChatIconButton
            data-testid="send-chat-button"
            onClick={handleSimulateEnter}
          >
            <ChatMessageIcon
              width={24}
              height={24}
              marginRight={0}
              src={SendChatButton}
            />
          </ChatIconButton>
        ) : (
          renderSendButton()
        )}
      </ChatField>
    </BottomContainerWrapper>
  );
};

export default ChatFooter;

const BottomContainerWrapper = styled.div`
  display: flex;
  width: 460px;
  position: absolute;
  bottom: 0;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  background-color: ${colors.white};
  flex-direction: column;
`;

const ChatField = styled.div`
  display: flex;
  height: 98px;
  padding: 0px 40px;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  align-self: stretch;
`;

const ChatIconButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  position: relative;
`;

const ChatMessageIcon = styled.img<{
  width?: number;
  height?: number;
  marginRight?: number;
}>`
  width: ${props => `${props.width ?? 32}px`};
  height: ${props => `${props.height ?? 32}px`};
  flex-shrink: 0;
  margin-right: ${props => `${props.marginRight ?? 16}px`};
`;
