import React, {useCallback, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {FormControlLabel} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import {BreadCrumbNavigator} from '../../../shared/components/breadCrumbNavigator';
import {Button} from '../../../shared/components/button';
import {InputRadioButton} from '../../../shared/components/form';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {Crumb} from '../../../shared/interfaces/crumb';
import {
  ENROLLMENT_METHOD_ENUM,
  PatientInvitation,
} from '../../../shared/interfaces/patient';
import {colors} from '../../../shared/styles/theme';
import {fetcher} from '../../../shared/utils/fetcher';
import {urls} from '../../../shared/utils/urls';
import {MainHeader} from '../../layout/components/mainHeader';
import {PORTAL_PATIENTS_ROUTES} from '../constants/routes';
import {
  INVITATION_SUCCESS_REQUEST_STATUS,
  INVITATION_SUCCESS_REQUEST_STATUS_MESSAGES,
  InvitationError,
} from '../constants/types';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    paddingLeft: 40,
    paddingTop: 24,
  },
  radioButton: {
    width: 386,
    height: 56,
    padding: 0,
    borderRadius: 4,
    border: `1px solid ${colors.primary}`,
    marginLeft: 0,
    marginBottom: 12,
    marginTop: 4,
  },
  bottomButtonContainer: {
    marginTop: 28,
    display: 'flex',
  },
}));

interface Props {
  crumbs: Crumb[];
  title: string;
}

interface Params {
  isEnrolled: boolean;
  values: PatientInvitation;
}

export const PatientEnrollmentStatusPage: React.FC<Props> = ({
  crumbs,
  title,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const {state} = useLocation<Params>();
  const [isAppEnrollmentSelected, setIsAppEnrollmentSelected] = useState(true);
  const showMessage = useCustomizedSnackbar();
  const handleError = useErrorHandling();

  const getSuccessfulMessage = (
    response: INVITATION_SUCCESS_REQUEST_STATUS
  ): string => {
    if (
      response ===
      INVITATION_SUCCESS_REQUEST_STATUS.CONNECTION_REQUEST_WAS_SEND_SUCCESSFULLY
    ) {
      return INVITATION_SUCCESS_REQUEST_STATUS_MESSAGES[
        INVITATION_SUCCESS_REQUEST_STATUS
          .CONNECTION_REQUEST_WAS_SEND_SUCCESSFULLY
      ];
    }
    return INVITATION_SUCCESS_REQUEST_STATUS_MESSAGES[
      INVITATION_SUCCESS_REQUEST_STATUS.INVITATION_WAS_SEND_SUCCESSFULLY
    ];
  };

  const handleSendInvite = useCallback(async () => {
    try {
      const nextRoute = state.isEnrolled
        ? PORTAL_PATIENTS_ROUTES.CONNECTIONS
        : PORTAL_PATIENTS_ROUTES.INVITATIONS;
      const data = {
        ...state.values,
        enrollment_method: isAppEnrollmentSelected
          ? ENROLLMENT_METHOD_ENUM.MOBILE_APP
          : ENROLLMENT_METHOD_ENUM.ONBOARDING_PORTAL,
      };
      // Pending refactor on fetcher to TS to accept types different from null | undefined of data
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: disable-next-line
      const response = await fetcher(urls.invitePatient, 'POST', data);
      showMessage(getSuccessfulMessage(response), 'success');
      history.push(nextRoute);
    } catch (error) {
      if (error instanceof Error) {
        switch (error?.message) {
          case InvitationError.patientIsAlreadyConnected:
            showMessage('This patient is already connected with you.', 'error');
            break;
          case InvitationError.patientIsEnrolledWithDifferentName:
            showMessage('This phone number is already registered.', 'error');
            break;
          case InvitationError.patientExists:
            showMessage('The patient has already been invited.', 'error');
            break;
          case InvitationError.patientOptedOut:
            showMessage(
              'The patient has opt out for receive messages.',
              'error'
            );
            break;
          default:
            return handleError(error);
        }
      } else {
        return handleError(error);
      }
    }
  }, [
    handleError,
    history,
    isAppEnrollmentSelected,
    showMessage,
    state.isEnrolled,
    state.values,
  ]);

  return (
    <div className={classes.container}>
      <BreadCrumbNavigator crumbs={crumbs} title={title} />
      <div className={classes.contentWrapper}>
        <MainHeader
          title={
            state.isEnrolled
              ? 'Patient is Already Enrolled'
              : 'Patient is not Enrolled. Select Enrollment Method.'
          }
          description={
            state.isEnrolled
              ? 'This patient has already enrolled in Health Bank One. The patient will receive a request to connect in their mobile app. Click to send your connection request.'
              : 'Please select if patient can download the mobile app to enroll or if patient will enroll with a mobile browser.'
          }
        />
        {!state.isEnrolled && (
          <>
            <FormControlLabel
              control={<InputRadioButton />}
              checked={isAppEnrollmentSelected}
              onClick={() => setIsAppEnrollmentSelected(true)}
              label="Patient can enroll via the Health Bank One mobile app"
              className={classes.radioButton}
            />
            <FormControlLabel
              control={<InputRadioButton />}
              checked={!isAppEnrollmentSelected}
              onClick={() => setIsAppEnrollmentSelected(false)}
              label="Patient can enroll via a mobile browser"
              className={classes.radioButton}
            />
          </>
        )}
        <div className={classes.bottomButtonContainer}>
          <Button onClick={() => history.goBack()} variant="outlined">
            Back
          </Button>
          <Button onClick={handleSendInvite} type="submit">
            {state.isEnrolled ? 'Send' : 'Invite'}
          </Button>
        </div>
      </div>
    </div>
  );
};
